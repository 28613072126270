<template>
  <div>
    <header-slot />

    <commissions tab="8" />
  </div>
</template>

<script>
import Commissions from "@/views/commons/components/commissions/CommissionsComponent.vue";

export default {
  components: {
    commissions: Commissions,
  },
};
</script>

<style>
</style>